<template>
  <div
    style="background-color: black; position: fixed; width: 100vw; z-index: 999"
  >
    <div class="container hidden-md-and-down">
      <el-row>
        <el-col :span="5"
          ><div>
            <img src="../../assets/logo.png" alt="" /></div
        ></el-col>
        <el-col :span="17"
          ><div style="float: right; overflow: hidden">
            <el-menu
              :default-active="$route.path"
              mode="horizontal"
              text-color="#fff"
              active-text-color="#3BB59E"
              background-color="#000"
              class="ding"
              :router="true"
              :unique-opened="true"
            >
              <el-menu-item index="/">首页</el-menu-item>
              <el-submenu index="1">
                <template slot="title"
                  ><span @click="getParticular()" class="top"
                    >产品中心</span
                  ></template
                >
                <el-menu-item
                  class="productShowss"
                  v-for="item in this.lock"
                  :key="item.goods_id"
                  :index="item.model"
                >
                  <div @click="getShop(item)">
                    <img :src="item.imageUrl" alt="" />
                    <p>{{ item.goods_name }}</p>
                  </div>
                </el-menu-item>
              </el-submenu>
              <el-menu-item index="/platform2">数据安全</el-menu-item>
              <el-menu-item index="/join">招商加盟</el-menu-item>
              <el-menu-item index="/serve">服务与支持</el-menu-item>
              <el-submenu index="5">
                <template slot="title"><span class="top">下载</span></template>
                <el-menu-item class="" :index="indexID">
                  <div style="color: #000">
                    <ul>
                      <li>
                        <h3>宇起密码器浏览器插件</h3>
                        <p v-for="item in inPlug" :key="item.id">
                          <img
                            style="
                              width: 1.375rem;
                              height: auto;
                              margin-right: 0.3125rem;
                            "
                            :src="item.img"
                            alt=""
                          />
                          <span @click="getPlug(item.id)">{{ item.name }}</span>
                        </p>
                      </li>
                      <li>
                        <h3>宇起密码器电脑客户端</h3>
                        <p v-for="item2 in yuqi" :key="item2.id">
                          <img
                            style="
                              width: 1.375rem;
                              height: auto;
                              margin-right: 0.3125rem;
                            "
                            :src="item2.img"
                            alt=""
                          />
                          <span @click="getPlug(item2.id)">{{
                            item2.name
                          }}</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </el-menu-item>
              </el-submenu>
              <el-submenu index="6">
                <template slot="title"
                  ><span class="top">关于我们</span></template
                >
                <el-menu-item class="productShows" index="/synopsis"
                  >公司简介</el-menu-item
                >
                <el-menu-item class="productShows" index="/new"
                  >新闻动态</el-menu-item
                >
                <el-menu-item class="productShows" index="/recruit"
                  >招贤纳士</el-menu-item
                >
                <el-menu-item class="productShows" index="/relation"
                  >联系我们</el-menu-item
                >
              </el-submenu>
            </el-menu>
          </div></el-col
        >
        <el-col :span="3"><div></div></el-col>
      </el-row>
    </div>
    <div class="containerTow hidden-lg-and-up">
      <div>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col style="width: 6.25rem"
            ><div class="grid-content bg-purple">
              <i
                v-if="!show3"
                class="el-icon-s-unfold"
                style="font-size: 1.6rem"
                @click="show3 = !show3"
              ></i>
              <i
                v-if="show3"
                class="el-icon-close"
                style="font-size: 1.6rem"
                @click="show3 = !show3"
              ></i></div
          ></el-col>
          <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <img
                style="width: 8rem"
                src="../../assets/logo.png"
                alt=""
              /></div
          ></el-col>
          <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
        </el-row>
      </div>
    </div>
    <div class="hidden-lg-and-up">
      <el-collapse-transition>
        <div
          v-show="show3"
          style="height: calc(100vh - 45px); background-color: #000"
          class="transition-box"
        >
          <div style="height: calc(100vh - 3.75rem); padding: 0 1rem">
            <el-menu
              :default-active="$route.path"
              text-color="#fff"
              active-text-color="#3BB59E"
              background-color="#000"
              @select="handleSelect"
              class="containerTow-content"
              :router="true"
              :unique-opened="true"
            >
              <el-menu-item index="/">首页</el-menu-item>
              <el-submenu index="2">
                <template slot="title"
                  ><span class="top">产品中心</span></template
                >
                <el-menu-item
                  v-for="item in this.lock"
                  :key="item.goods_id"
                  :index="item.model"
                  ><img
                    @click="getShop(item)"
                    style="width: 50px"
                    :src="item.imageUrl"
                    alt=""
                  />
                  {{ item.goods_name }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item index="/platform2">数据安全</el-menu-item>
              <el-menu-item index="/join">招商加盟</el-menu-item>
              <el-menu-item index="/serve">服务与支持</el-menu-item>
              <el-submenu index="6">
                <template slot="title"
                  ><span class="top">关于我们</span></template
                >
                <el-menu-item index="/synopsis">公司简介</el-menu-item>
                <el-menu-item index="/new">新闻动态</el-menu-item>
                <el-menu-item index="/recruit">招贤纳士</el-menu-item>
                <el-menu-item index="/relation">联系我们</el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </el-collapse-transition>
    </div>
  </div>
</template>

<script>
import { getFingerprint, plugIn } from "../../api/home";
export default {
  data() {
    return {
      isShow: false,
      show3: false,
      lock: "",
      inPlug: [
        {
          id: "01",
          img: require("../../assets/mock/browser/图像 83.png"),
          name: "谷歌浏览器插件>",
        },
        // {
        //   id: "02",
        //   img: require("../../assets/mock/browser/图像 84.png"),
        //   name: "火狐浏览器插件>",
        // },
        // {
        //   id: "04",
        //   img: require("../../assets/mock/browser/04.png"),
        //   name: "Edge浏览器插件>",
        // },
      ],
      yuqi: [
        {
          id: "03",
          img: require("../../assets/mock/browser/蒙版组 28.png"),
          name: "宇起助手>",
        },
      ],
      indexID: "",
    };
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
    this.getLock();
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
      this.show3 = false;
    },
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      if (this.oldScrollTop >= 400) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        // console.log("header  你已经到底部了");
      }
      if (scrollStep > 50) {
        // console.log("header 滚动条向上滚动了！");
      } else {
        // console.log("header  滚动条向下滚动了！");
      }
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        // console.log("header 到了最顶部");
      }
    },
    // 产品中心
    getLock() {
      getFingerprint().then((res) => {
        this.lock = res.data.code;
      });
    },
    // 插件跳转
    getPlug(id) {
      console.log(id);
      if (id == "01") {
        this.indexID = `/chrome?id=${id}`;
        this.$router.push({ path: "/chrome", query: { id } });
      } else {
        this.indexID = `/inPlug?id=${id}`;
        this.$router.push(
          { path: "/inPlug", query: { id } },
          () => {},
          () => {}
        );
      }
    },
    getParticular() {
      this.$router.push("/particular");
    },
    // 跳转
    getShop(model) {
      this.$router.push({ path: item.model });
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 80.125vw;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  line-height: 3.75rem;
  overflow: hidden;

  img {
    vertical-align: middle;
  }
  .el-menu.el-menu--horizontal {
    border: 0;
  }
}

.containerTow {
  color: #fff;
  line-height: 3.75rem;
  text-align: center;
}
.el-menu {
  border-right: 0;
}
</style>
